import { ProgramLinksList } from '../components/Program/ProgramLinkList';

function ProgramLinksRoute() {
  return <ProgramLinksList />;
}

export const Component = ProgramLinksRoute;

export function clientLoader() {
  return null;
}
